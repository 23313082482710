import PropTypes from 'prop-types'
import { Form, Modal, Row, Col, Input } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
function UserGuild(props) {
  const { t } = useTranslation()
  const { isShowModal, onClose } = props
  const [formSearch] = Form.useForm()
  const [member, setMember] = useState()

  const handleClose = () => {
    onClose()
  }
  const handleSearch = (values) => {
    setMember(values?.keyword)
  }
  return (
    <Modal
      title={<>{t('managementGuilds.form.userGuild')}</>}
      onCancel={handleClose}
      open={isShowModal}
      width={950}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="flex sm:justify-end w-full">
        <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
          <Form
            onFinish={handleSearch}
            className="w-full sm:w-auto"
            form={formSearch}
            translate="yes"
            autoComplete="off"
            size="middle"
            validateTrigger={['onBlur', 'onChange']}
            requiredMark={false}
          >
            <Form.Item
              className="w-full"
              label={
                <span className="font__M__plus font-bold">
                  {t('common.search')}
                </span>
              }
              name="keyword"
              labelAlign="left"
            >
              <Input className="w-full" placeholder={t('common.search')} />
            </Form.Item>
          </Form>
        </div>
      </div>

      <div
        style={{
          background: '#1890ff',
          borderRadius: '8px',
          boxShadow: '0 0 6px 0 #2ca0ffcc',
          fontFamily: 'Rexliafree',
        }}
      >
        {/* Title */}
        <Row className="p-[12px]">
          <Col
            span={7}
            className="flex justify-center items-center  text-[16px]"
          >
            Member 8/10
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center text-[16px]  "
          >
            Rank
          </Col>
          <Col
            span={7}
            className="flex justify-center items-center text-[16px] "
          >
            Contribution
          </Col>
          <Col
            span={4}
            className="flex justify-center items-center text-[16px] "
          >
            Role
          </Col>
          <Col
            span={3}
            className="flex justify-center items-center  text-[16px]"
          >
            Online 3/8
          </Col>
        </Row>

        {/* Content */}
        <div
          className="guildInfo"
          style={{
            background: '#0D2758',
            borderRadius: '0 0 8px 8px',
            maxHeight: '465px',
            overflowY: 'auto',
          }}
        >
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <img
              className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
              src={`${process.env.PUBLIC_URL}/svgs/guild/guildMaster.svg`}
              alt=""
            />
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              Guild Master
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[16px] text-[#2FFF39] "
            >
              Online
            </Col>
          </Row>
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <img
              className="absolute left-[15px] bottom-[28px] w-[25px] h-[25px]"
              src={`${process.env.PUBLIC_URL}/svgs/guild/subGuild.svg`}
              alt=""
            />
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              Sub Master 1
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[16px] text-[#2FFF39] "
            >
              Online
            </Col>
          </Row>
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              Role 1
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[14px] flex-col "
            >
              <div>Last Login</div>
              <div>2 days ago</div>
            </Col>
          </Row>
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              Role 2
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[14px] flex-col "
            >
              <div>Last Login</div>
              <div>2 days ago</div>
            </Col>
          </Row>
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
              marginBottom: '20px',
            }}
          >
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              General
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[14px] flex-col "
            >
              <div>Last Login</div>
              <div>2 days ago</div>
            </Col>
          </Row>
          <Row
            style={{
              borderRadius: '8px',
              position: 'relative',
            }}
          >
            <Col
              span={7}
              className=" flex justify-center items-center text-[16px]"
            >
              <img
                className=" w-[25px] h-[25px] mr-1"
                src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                alt=""
              />
              <span>Player Name</span>
            </Col>

            <Col
              span={3}
              className="flex justify-center items-center text-[16px] "
            >
              Intermediate+
            </Col>
            <Col
              span={7}
              className="flex justify-center items-center text-[16px] p-[5px] "
            >
              <Row>
                <Col span={8} className="flex justify-center items-center ">
                  <img
                    className="w-[20px] h-[20px] mr-3 "
                    src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                    alt=""
                  />
                  <div>
                    <div>Total</div>
                    <div>Month</div>
                    <div>Cycle</div>
                  </div>
                </Col>
                <Col span={16} className="flex justify-end items-center ">
                  <div className="flex items-center flex-col ">
                    <div>15,223</div>
                    <div>123</div>
                    <div>50</div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              span={4}
              className="flex justify-center items-center text-[16px] "
            >
              General
            </Col>
            <Col
              span={3}
              className="flex justify-center items-center text-[14px] flex-col "
            >
              <div>Last Login</div>
              <div>20 days ago</div>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

UserGuild.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default UserGuild

export const GET_LIST_GUILD_START = 'GET_LIST_GUILD_START'
export const GET_LIST_GUILD_SUCCESS = 'GET_LIST_GUILD_SUCCESS'
export const GET_LIST_GUILD_FAILED = 'GET_LIST_GUILD_FAILED'

//------------------------- Shop ------------------------//
/**
 * [1]
 * get list guild start action
 * @param {any} payload
 * @param {function} onSuccess Callback function on success
 * @param {*} onError Callback function on error
 * @returns {object}
 */
export function getListGuilds(payload, onSuccess, onError) {
  return {
    type: GET_LIST_GUILD_START,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

/**
 * get list guild success action
 * @param {*} payload
 * @returns {object}
 */
export function getListGuildsSuccess(payload) {
  return {
    type: GET_LIST_GUILD_SUCCESS,
    payload: payload,
  }
}

/**
 * get list guild failed action
 * @returns {object}
 */
export function getListGuildsFailed() {
  return {
    type: GET_LIST_GUILD_FAILED,
  }
}

export default {
  getListGuilds,
  getListGuildsSuccess,
  getListGuildsFailed,
}

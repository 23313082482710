import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Col, Modal, Row, Tooltip } from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import { formatAddress, formatNumber } from '~/helpers/common'
import configs from '~/configs'
import { SyncOutlined } from '@ant-design/icons'
import TimeHelper from '~/helpers/time-helper'

function UserRow({ item, index, totalItems }) {
  const nameRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    if (nameRef.current) {
      setIsTruncated(nameRef.current.scrollWidth > nameRef.current.clientWidth)
    }
  }, [item?.user_name])

  return (
    <Row
      key={index}
      style={{
        background: '#0D2758',
        padding: '16px',
        borderBottom:
          index !== totalItems - 1
            ? '1px solid rgb(36, 169, 250, 0.3)'
            : 'none',
        borderRadius: index === totalItems - 1 ? '0 0 8px 8px' : '0',
      }}
    >
      <Tooltip title={isTruncated ? item?.user_name : ''}>
        <Col
          span={5}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
          className={isTruncated ? '' : 'flex items-center justify-center'}
          ref={nameRef}
        >
          {item?.user_name}
        </Col>
      </Tooltip>

      <Col span={4} className="flex items-center justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${configs.NETWORK.scan}address/${item?.address_wallet}`}
          style={{ color: '#40A9FF' }}
        >
          {formatAddress(item?.address_wallet)}
        </a>
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.rank}
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.ranking ? item?.ranking : 'ー'}
      </Col>
      <Col span={2} className="flex items-center justify-center">
        {item?.bit ? formatNumber(item?.bit) : 'ー'}
      </Col>
      <Col span={3} className="flex items-center justify-center">
        {item?.ma_point ? formatNumber(item?.ma_point) : 'ー'}
      </Col>
      <Col span={2} className="flex items-center justify-center">
        {TimeHelper(item?.crt_dt)}
      </Col>
      <Col
        span={2}
        className="flex items-center justify-center text-[#40A9FF] text-[20px] cursor-pointer"
      >
        <SyncOutlined />
      </Col>
    </Row>
  )
}

function SectorDetail(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, dataSector } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Modal
      title={'Sector Detail'}
      onCancel={handleClose}
      open={isShowModal}
      width={1300}
      centered
      destroyOnClose
      footer={null}
    >
      <div className="ContainerGiftDetail">
        <Row
          style={{
            fontFamily: 'Rexliafree',
            padding: '16px',
          }}
        >
          <Col
            span={5}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.name')}
          </Col>
          <Col
            span={4}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.walletAddress')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.rank')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.ranking')}
          </Col>
          <Col
            span={2}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.bit')}
          </Col>
          <Col
            span={3}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.maPoint')}
          </Col>
          <Col
            span={2}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('managementUsers.form.createdAt')}
          </Col>
          <Col
            span={2}
            style={{ fontSize: '16px' }}
            className="flex items-center justify-center"
          >
            {t('common.action')}
          </Col>
        </Row>

        {dataSector?.user?.map((item, index) => (
          <UserRow
            key={index}
            item={item}
            index={index}
            totalItems={dataSector.user.length}
          />
        ))}
      </div>
    </Modal>
  )
}

SectorDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default SectorDetail

export const fakeDataSponsor = {
  message: 'Get users successfully!',
  total: 2,
  currentPage: 1,
  hasPreviousPage: false,
  hasNextPage: false,
  pageSize: 10,
  data: [
    {
      lang: 'en',
      _id: '66ce8e0893924b79a751d3f8',
      user_id: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      user_name: 'TuanVM',
      address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      tournamentImage: 'test',
      tournamentName: 'Tournament 1',
      status: 'In progress',
      time: '2022-01-02 20:00:00',
    },
    {
      lang: 'en',
      _id: '66ce8e0893924b79a751d3f8',
      user_id: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      user_name: 'TuanVM',
      address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A1',
      tournamentImage: 'test',
      tournamentName: 'Tournament 2',
      status: 'Done',
      time: '2022-01-30 15:00:00',
    },
    {
      lang: 'en',
      _id: '66ce8e0893924b79a751d3f8',
      user_id: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      user_name: 'TuanVM',
      address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      tournamentImage: 'test',
      tournamentName: 'Tournament 3',
      status: 'Open',
      time: '2022-01-25 17:30:00',
    },
    {
      lang: 'en',
      _id: '66ce8e0893924b79a751d3f8',
      user_id: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      user_name: 'TuanVM',
      address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A4',
      tournamentImage: 'test',
      tournamentName: 'Tournament 4',
      status: 'Within 3 hours',
      time: '2022-01-01 01:00:00',
    },
  ],
}

import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import {
  Form,
  Modal,
  Input,
  Button,
  notification,
  Row,
  Col,
  DatePicker,
} from 'antd'
import React, { useEffect } from 'react'
import {
  NOTIFICATION_DURATION,
  cancelButtonStyle,
  okButtonStyle,
} from '~/common/constants'
import { useAdmin } from '~/views/app/redux/hooks/useAdmin'
import { REGEX } from '~/helpers/regex'
import moment from 'moment'
import { usePools } from '~/views/app/redux/hooks/usePools'
function RankingPoolForm(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, ranking } = props

  const [form] = Form.useForm()
  const rules = {
    poolName: [
      () => ({
        validator(rule, value) {
          const regex = new RegExp(REGEX.PROFILE_NAME)
          if (value && value?.charAt(0) === '_') {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && value?.indexOf('-') !== -1) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          if (value && !regex.test(value)) {
            return Promise.reject(
              'Name is a string of maximum 15 characters, which may include Latin letters, Japanese characters, numbers, spaces, or underscores, but must begin with a Latin letter, Japanese character or number.',
            )
          }
          return Promise.resolve()
        },
      }),

      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.poolName'),
        }),
      },
    ],
    poolTotal: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.poolTotal'),
        }),
      },
    ],
    tournament: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.tournament'),
        }),
      },
    ],
    weekly: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.weekly'),
        }),
      },
    ],
    pvp: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.pvp'),
        }),
      },
    ],
    exploraion: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.exploraion'),
        }),
      },
    ],
    guild: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.guild'),
        }),
      },
    ],
    sector: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.sector'),
        }),
      },
    ],
    from: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.from'),
        }),
      },
    ],
    to: [
      {
        required: true,
        message: t('validate.required', {
          0: t('managementPools.rankingRewardsPool.form.to'),
        }),
      },
    ],
  }

  
  const {
    actions,
    data: { isLoadingCreatePool },
  } = usePools()

  const handleSubmit = (values) => {
    const formValue = {
      name: values?.poolName,
      totalReward: Number(values?.poolTotal),
      guild: values?.guild,
      sector: values?.sector,
      weekly: values?.weekly,
      personalPvP: values?.pvp,
      personalExploraion: values?.exploraion,
      tournament: values?.tournament,
      // timeStart: values?.from,
      // timeEnd: values?.to,
      timeStart: 100,
      timeEnd: 200,
    }

    actions?.createPool(formValue, (res) => {
      if (res) {
        setTimeout(()=> {
          onSuccess()
          refreshData()
        }, 3000)
      }
    })
  }

  const onSuccess = () => {
    notification.success({
      message: id
        ? t('common.message.updateSuccessfully')
        : t('common.message.createSuccessfully'),
      duration: NOTIFICATION_DURATION,
    })
    onClose()
    refreshData()
  }

  const handleClose = () => {
    form.resetFields()
    onClose()
  }

  useEffect(() => {
    if (id) {
      const formDetailData = {
        poolName: ranking?.poolName,
        poolTotal: ranking?.poolTotal,
        weekly: ranking?.weekly,
        cycle: ranking?.cycle,
        guild: ranking?.guild,
        sector: ranking?.sector,
        from: ranking?.from ? moment(ranking?.from) : null,
        to: ranking?.to ? moment(ranking?.to) : null,
      }
      form.setFieldsValue(formDetailData)
    }
  }, [id])
  return (
    <Modal
      title={
        id
          ? t('managementPools.rankingRewardsPool.editTitle')
          : t('managementPools.rankingRewardsPool.addTitle')
      }
      onCancel={handleClose}
      open={isShowModal}
      width={600}
      centered
      destroyOnClose
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        translate="yes"
        autoComplete="off"
        size="middle"
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          label={t('managementPools.rankingRewardsPool.form.poolName')}
          name="poolName"
          rules={rules.poolName}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementPools.rankingRewardsPool.form.poolName')}
          />
        </Form.Item>

        <Form.Item
          label={t('managementPools.rankingRewardsPool.form.poolTotal')}
          name="poolTotal"
          rules={rules.poolTotal}
          labelAlign="left"
        >
          <Input
            placeholder={t('managementPools.rankingRewardsPool.form.poolTotal')}
          />
        </Form.Item>

        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.tournament')}
              rules={rules.tournament}
              name="tournament"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.tournament',
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.weekly')}
              rules={rules.weekly}
              name="weekly"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.weekly',
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.pvp')}
              rules={rules.pvp}
              name="pvp"
              labelAlign="left"
            >
              <Input
                placeholder={t('managementPools.rankingRewardsPool.form.pvp')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.exploraion')}
              rules={rules.exploraion}
              name="exploraion"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.exploraion',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.guild')}
              rules={rules.guild}
              name="guild"
              labelAlign="left"
            >
              <Input
                placeholder={t('managementPools.rankingRewardsPool.form.guild')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.sector')}
              rules={rules.sector}
              name="sector"
              labelAlign="left"
            >
              <Input
                placeholder={t(
                  'managementPools.rankingRewardsPool.form.sector',
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={100}>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.from')}
              rules={rules.from}
              name="from"
              labelAlign="left"
            >
              <DatePicker
                className="dateRanking"
                style={{ background: '#fff', width: '100%', height: '43px' }}
                placeholder={t('managementPools.rankingRewardsPool.form.from')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('managementPools.rankingRewardsPool.form.to')}
              rules={rules.to}
              name="to"
              labelAlign="left"
            >
              <DatePicker
                className="dateRanking"
                style={{ background: '#fff', width: '100%', height: '43px' }}
                placeholder={t('managementPools.rankingRewardsPool.form.to')}
              />
            </Form.Item>
          </Col>
        </Row>

        <div key="buttons" className="flex justify-center">
          <Button key="cancel" onClick={handleClose} style={cancelButtonStyle}>
            {t('common.btn.cancel')}
          </Button>
          <Button
            className="border-hidden"
            key="ok"
            htmlType="submit"
            type="primary"
            style={okButtonStyle}
            disabled={isLoadingCreatePool}
            loading={isLoadingCreatePool}
          >
            {id ? t('common.btn.update') : t('common.btn.create')}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

RankingPoolForm.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default RankingPoolForm

import { combineReducers } from 'redux'

//reducers
import commonManagement from './common'
import historyTradeManagement from './history-trade'
import marketplaceSettingsManagement from './marketplace-settings'
import poolsManagement from './pools'
import shopManagement from './shop'
import adminManagement from './admin'
import userManagement from './user'
import collaborationManagement from './collaboration'
import profileManagement from './profile'
import dashboardManagement from './dashboard'
import giftManagement from './gift'
import guildManagement from './guild'
//combine reducers
export default combineReducers({
  commonManagement,
  historyTradeManagement,
  marketplaceSettingsManagement,
  shopManagement,
  poolsManagement,
  adminManagement,
  userManagement,
  collaborationManagement,
  profileManagement,
  dashboardManagement,
  giftManagement,
  guildManagement,
})

import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Image, Input, Row, Space } from 'antd'
import React, { useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { EyeOutlined } from '@ant-design/icons'
import TimeHelper from '~/helpers/time-helper'
import SponsorTournamentDetail from './modalDetailSponsor'
import { fakeDataSponsor } from './fakedata'

const SponsorTournament = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal

  const [modalDetail, setModalDeail] = useState({
    id: null,
    isOpenModal: false,
    sponsor: {},
  })

  const getColor = (color) => {
    switch (color) {
      case 'In progress':
        return {
          border: '1px solid rgba(102, 187, 106, 1)',
          background: 'rgba(102, 187, 106, 1)',
        }
      case 'Done':
        return {
          border: '1px solid rgba(255, 0, 0, 1)',
          background: 'rgba(255, 0, 0, 1)',
        }
      case 'Open':
        return {
          border: '1px solid rgba(237, 135, 0, 1)',
          background: 'rgba(237, 135, 0, 1)',
        }
      default:
        return {
          border: '1px solid rgba(12, 200, 254, 1)',
          background: 'rgba(24, 45, 86, 1)',
        }
    }
  }
  const columns = [
    {
      title: t('sponsorsTournament.tournamentImage'),
      dataIndex: 'tournamentImage',
      key: 'tournamentImage',
      width: 200,
      render: (tournamentImage) => {
        return (
          <div className="">
            <Image
              className="imageTournament"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={`${process.env.PUBLIC_URL}/imgs/tournament/${tournamentImage}.png`}
            />
          </div>
        )
      },
    },
    {
      title: (
        <div className="text-[16px] flex  justify-center">
          {t('sponsorsTournament.tournamentName')}
        </div>
      ),
      dataIndex: 'tournamentName',
      key: 'tournamentName',
      render: (tournamentName) => {
        return (
          <div
            className="text-[16px] flex  justify-center"
            style={{ fontFamily: 'Rexliafree' }}
          >
            {tournamentName}
          </div>
        )
      },
    },
    {
      title: (
        <div className="text-[16px] flex  justify-center">
          {t('sponsorsTournament.status')}
        </div>
      ),
      dataIndex: 'status',
      key: 'status',

      render: (status) => {
        return (
          <div
            className="text-[16px] flex  justify-center"
            style={{
              fontFamily: 'Rexliafree',
              ...getColor(status),
              padding: '10px',
              borderRadius: '16px',
            }}
          >
            <div>{status}</div>
            {/* {(status === 'In progress' ||
              status === 'Jump-in' ||
              status === 'Entry') && (
              <img
                className="w-[20px] h-[20px] ml-2 mt-[2px]"
                src={`${process.env.PUBLIC_URL}/svgs/tournament/${status}.svg`}
                alt=""
              />
            )} */}
          </div>
        )
      },
    },
    {
      title: (
        <div className="text-[16px] flex  justify-center">
          {t('sponsorsTournament.time')}
        </div>
      ),
      dataIndex: 'time',
      key: 'time',
      render: (time) => {
        const date = new Date(time)

        // Format the date
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
        }).format(date)

        // Format the time
        const formattedTime = date.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
        return (
          <div
            className="text-[16px] flex  justify-center"
            style={{ fontFamily: 'Rexliafree' }}
          >
            {`${formattedDate} at ${formattedTime}`}
          </div>
        )
      },
    },

    {
      //   title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalDetail(data?._id, data)}
              icon={<EyeOutlined style={{ color: '#1890FF' }} />}
            />
          </Space>
        )
      },
    },
  ]

  // Handle delete

  const onShowModalDetail = (id, sponsor) => {
    setModalDeail({
      id,
      isOpenModal: true,
      sponsor: sponsor,
    })
  }

  const onCloseModalDetail = () => {
    setModalDeail({
      id: null,
      isOpenModal: false,
      sponsor: {},
    })
  }

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}></Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={fakeDataSponsor.data}
        // loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: fakeDataSponsor.total,
          keyword,
          setKeyword,
        }}
      />

      {modalDetail.isOpenModal && (
        <SponsorTournamentDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          // refreshData={refreshData}
          id={modalDetail.id}
          sponsor={modalDetail?.sponsor}
        />
      )}
    </div>
  )
}

export default SponsorTournament

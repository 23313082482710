import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Form, Input, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import { DeleteFilled, EditFilled, EyeOutlined } from '@ant-design/icons'
import RankingPoolForm from './form'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import RankingPoolDetail from './modalDetail'
import { usePools } from '~/views/app/redux/hooks/usePools'
import { DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2 } from '~/common/constants'
import moment from 'moment'
import DeletePoolCommon from '~/common/hooks/useDeletePool'

const RankingPool = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [idSelected, setIdSelected] = useState([])
  const [isModalOpenDelete, setIsModalOpenDelete] = useState({
    isOpenModal: false,
    ranking: {},
  })
  const {
    actions,
    data: { listPool, isLoadingListPool },
  } = usePools()

  useEffect(() => {
    actions?.getListPools()
  }, [])

  const refreshData = () => {
    actions?.getListPools()
  }

  const {
    data: { user, total },
  } = useTheme()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })
  const [modalDetail, setModalDeail] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })

  const showModalDelete = (content, data) => {
    setIsModalOpenDelete({
      content: content,
      data: data,
      isOpenModal: true,
    })
  }
  const handleCancelDelete = () => {
    setIsModalOpenDelete({
      content: null,
      data: null,
      isOpenModal: false,
    })
  }

  const columns = [
    {
      title: t('managementPools.rankingRewardsPool.form.poolName'),
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return <div className="">{name}</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.poolTotal'),
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => {
        return <div className="">{totalAmount} OAS</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.tournament'),
      dataIndex: 'subPools',
      key: 'tournament',
      render: (subPools) => {
        return <div className="">{subPools.tournament.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.weekly'),
      dataIndex: 'subPools',
      key: 'weekly',
      render: (subPools) => {
        return <div className="">{subPools.weekly.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.pvp'),
      dataIndex: 'subPools',
      key: 'pvp',
      render: (subPools) => {
        return <div className="">{subPools.personalPvP.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.exploraion'),
      dataIndex: 'subPools',
      key: 'exploraion',
      render: (subPools) => {
        return <div className="">{subPools.personalExploraion.percentage}%</div>
      },
    },

    {
      title: t('managementPools.rankingRewardsPool.form.guild'),
      dataIndex: 'subPools',
      key: 'guild',
      render: (subPools) => {
        return <div className="">{subPools.guild.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.sector'),
      dataIndex: 'subPools',
      key: 'sector',
      render: (subPools) => {
        return <div className="">{subPools.sector.percentage}%</div>
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.from'),
      dataIndex: 'timeStart',
      key: 'from',
      render: (timeStart) => {
        return moment(timeStart * 1000)
          .utc()
          .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
      },
    },
    {
      title: t('managementPools.rankingRewardsPool.form.to'),
      dataIndex: 'timeEnd',
      key: 'to',
      render: (timeEnd) => {
        return moment(timeEnd * 1000)
          .utc()
          .format(DATE_TIMEFORMAT_YYYY_MM_dd_HH_mm2)
      },
    },

    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={<EditFilled style={{ color: '#36CFC9' }} />}
              onClick={() => onShowModalForm(data?._id, data)}
            />

            <Button
              type="ghost"
              loading={false}
              disabled={data?.address_wallet === user?.address_wallet}
              onClick={() => {
                onRemove(data)
              }}
              icon={<DeleteFilled style={{ color: '#FF4D4F' }} />}
            />
            <Button
              type="ghost"
              loading={false}
              onClick={() => onShowModalDetail(data?._id, data?.subPools)}
              icon={<EyeOutlined style={{ color: '#1890FF' }} />}
            />
          </Space>
        )
      },
    },
  ]

  const rowClassName = (record) => {
    return record?.address_wallet === user?.address_wallet
      ? 'highlight-row'
      : ''
  }

  // Handle delete
  const onRemove = (data) => {
    showModalDelete(t('cancel_modal.question', { question: "" }), data)
  }

  const onShowModalForm = (id, ranking) => {
    setModalForm({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }
  const onShowModalDetail = (id, ranking) => {
    setModalDeail({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }

  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }
  const onCloseModalDetail = () => {
    setModalDeail({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }

  useEffect(() => {
    setIdSelected([])
  }, [keyword])

  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <Space className="d-flex justify-content-between justify-content-sm-start">
            <Button
              type="primary"
              onClick={() => {
                onShowModalForm(null, {})
              }}
            >
              {t('managementPools.rankingRewardsPool.create')}
            </Button>
          </Space>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <Form
                onFinish={handleSearch}
                className="w-full sm:w-auto"
                form={formSearch}
                translate="yes"
                autoComplete="off"
                size="middle"
                validateTrigger={['onBlur', 'onChange']}
                requiredMark={false}
              >
                <Form.Item
                  className="w-full"
                  label={
                    <span className="font__M__plus font-bold">
                      {t('common.search')}
                    </span>
                  }
                  name="keyword"
                  labelAlign="left"
                >
                  <Input className="w-full" placeholder={t('common.search')} />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listPool}
        rowClassName={rowClassName}
        loading={isLoadingListPool}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {modalForm.isOpenModal && (
        <RankingPoolForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          ranking={modalForm?.ranking}
        />
      )}
      {modalDetail.isOpenModal && (
        <RankingPoolDetail
          isShowModal={modalDetail.isOpenModal}
          onClose={onCloseModalDetail}
          refreshData={refreshData}
          id={modalDetail.id}
          ranking={modalDetail?.ranking}
        />
      )}

      <DeletePoolCommon
        action={{
          isModalOpen: isModalOpenDelete.isOpenModal,
          handleCancel: handleCancelDelete,
        }}
        refreshData={refreshData}
        user={user}
        content={isModalOpenDelete.content}
        dataPool={isModalOpenDelete.data}
        t={t}
      />
    </div>
  )
}

export default RankingPool

import { addKeyToList } from '~/helpers/transform-data'
import {
  GET_LIST_GUILD_START,
  GET_LIST_GUILD_SUCCESS,
  GET_LIST_GUILD_FAILED,
} from '../actions/guild'

const initialState = {
  isLoading: false,
  listGuild: [],
  total: 0,
}

/**
 * shop reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
export default function guildManagement(state = initialState, action) {
  switch (action.type) {
    case GET_LIST_GUILD_START:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LIST_GUILD_SUCCESS:
      return {
        ...state,
        listGuild: addKeyToList(
          action.payload?.listGuild,
          action.payload?.page,
          action.payload?.pageSize,
        ),
        total: action,
        isLoading: false,
      }

    case GET_LIST_GUILD_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export const fakeDataSector = {
  message: 'Get users successfully!',
  total: 2,
  currentPage: 1,
  hasPreviousPage: false,
  hasNextPage: false,
  pageSize: 10,
  data: [
    {
      _id: '66ce8e0893924b79a751d3f8',
      user_id: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
      sectorName: 'Sector 1',
      totalUser: 100,
      createAt: '2024-09-18',
      user: [
        {
          _id: '123',
          user_id: '11',
          user_name: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A',
          crt_dt: '2024-01-05T10:38:22.472Z',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
        {
          _id: '12223',
          user_id: '1',
          user_name: 'Luan',
          address_wallet: '0xDE108F603A85F24BaaF75Bb3b870a131b10b590A95',
          crt_dt: '2024-01-05T10:38:22.472Z',
          rank: 'Beginner',
          bit: 5061,
          ma_point: 50001,
          ranking: 'SSS',
        },
      ],
    },
  ],
}

import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Dropdown, Form, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { formatAddress } from '~/helpers/common'
import configs from '~/configs'
import { useUser } from '~/views/app/redux/hooks/useUser'
import { converUserRankUser } from '~/common/constants'

const WhiteList = () => {
  const { t } = useTranslation()
  const [formSearch] = Form.useForm()
  const [whiteList, setWhiteList] = useState([])
  console.log(123, whiteList)
  const USER_ROLE = [
    {
      key: '0',
      value: 'ranking',
      label: t(`managementUsers.roleUser.ranking`),
    },
    {
      key: '1',
      value: 'lastActive',
      label: t(`managementUsers.roleUser.lastActive`),
    },
    {
      key: '2',
      value: 'createdAt',
      label: t(`managementUsers.roleUser.createdAt`),
    },
  ]
  const [role, setRole] = useState(USER_ROLE[0])

  const {
    actions,
    data: { isLoading, listUser, total },
  } = useUser()

  const { page, pageSize, keyword, setPage, setPageSize, setKeyword } =
    useQueryState()

  //Control all modal
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    user: {},
  })

  const columns = [
    {
      title: t('common.no'),
      dataIndex: 'key',
      key: 'key',
      render: (data) => {
        return data
      },
    },
    {
      title: t('managementUsers.form.name'),
      dataIndex: 'user_name',
      key: 'user_name',
      render: (user_name) => {
        return <div className="">{user_name}</div>
      },
    },
    {
      title: t('managementUsers.form.walletAddress'),
      dataIndex: 'address_wallet',
      key: 'address_wallet',
      render: (address_wallet) => {
        let link = `${configs.NETWORK.scan}address/${address_wallet}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            title={address_wallet}
            style={{ color: '#40A9FF' }}
          >
            {formatAddress(address_wallet)}
          </a>
        )
      },
    },
    {
      title: t('managementUsers.form.rank'),
      dataIndex: 'playerGameProgress',
      key: 'playerGameProgress',
      render: (playerGameProgress) => {
        return <div>{converUserRankUser(playerGameProgress?.player_rank)}</div>
      },
    },
    {
      title: t('managementUsers.form.ranking'),
      dataIndex: 'ranking',
      key: 'ranking',
      render: (ranking) => {
        return <div className="">{ranking}</div>
      },
    },
    {
      title: t('managementUsers.form.bit'),
      dataIndex: 'bit_balance',
      key: 'bit_balance',
      render: (bit_balance) => {
        return <div className="">{bit_balance}</div>
      },
    },
    {
      title: t('managementUsers.form.maPoint'),
      dataIndex: 'mapoint_amount',
      key: 'mapoint_amount',
      render: (mapoint_amount) => {
        return <div className="">{mapoint_amount ? mapoint_amount : 0}</div>
      },
    },
    {
      title: t('managementUsers.form.lastActive'),
      dataIndex: 'lastActive',
      key: 'lastActive',
      render: (lastActive) => {
        return TimeHelper(lastActive)
      },
    },
    {
      title: t('managementUsers.form.createdAt'),
      dataIndex: 'crt_dt',
      key: 'crt_dt',
      render: (crt_dt) => {
        return TimeHelper(crt_dt)
      },
    },
    {
      title: t('common.action'),
      render: (_, data) => {
        return (
          <Space>
            <Button
              type="ghost"
              loading={false}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M17.7337 15.6133C17.3127 14.6159 16.7016 13.7099 15.9346 12.9459C15.17 12.1796 14.2642 11.5687 13.2672 11.1468C13.2583 11.1423 13.2494 11.1401 13.2404 11.1356C14.6311 10.1311 15.5351 8.49498 15.5351 6.649C15.5351 3.59096 13.0574 1.11328 9.99936 1.11328C6.94132 1.11328 4.46364 3.59096 4.46364 6.649C4.46364 8.49498 5.36766 10.1311 6.75829 11.1378C6.74936 11.1423 6.74043 11.1445 6.7315 11.149C5.7315 11.5709 4.83418 12.1758 4.06409 12.9481C3.29782 13.7128 2.68687 14.6186 2.26498 15.6155C1.85052 16.5915 1.627 17.6379 1.6065 18.6981C1.60591 18.7219 1.61008 18.7456 1.61879 18.7678C1.6275 18.79 1.64056 18.8102 1.6572 18.8273C1.67384 18.8443 1.69373 18.8579 1.71569 18.8671C1.73765 18.8764 1.76124 18.8811 1.78507 18.8811H3.12436C3.22257 18.8811 3.3007 18.803 3.30293 18.707C3.34757 16.9838 4.03954 15.37 5.26275 14.1468C6.52838 12.8811 8.20918 12.1847 9.99936 12.1847C11.7895 12.1847 13.4703 12.8811 14.736 14.1468C15.9592 15.37 16.6511 16.9838 16.6958 18.707C16.698 18.8052 16.7761 18.8811 16.8744 18.8811H18.2136C18.2375 18.8811 18.2611 18.8764 18.283 18.8671C18.305 18.8579 18.3249 18.8443 18.3415 18.8273C18.3582 18.8102 18.3712 18.79 18.3799 18.7678C18.3886 18.7456 18.3928 18.7219 18.3922 18.6981C18.3699 17.6311 18.1489 16.5932 17.7337 15.6133ZM9.99936 10.4883C8.97481 10.4883 8.01052 10.0887 7.28507 9.36328C6.55963 8.63784 6.16007 7.67355 6.16007 6.649C6.16007 5.62444 6.55963 4.66016 7.28507 3.93471C8.01052 3.20926 8.97481 2.80971 9.99936 2.80971C11.0239 2.80971 11.9882 3.20926 12.7136 3.93471C13.4391 4.66016 13.8386 5.62444 13.8386 6.649C13.8386 7.67355 13.4391 8.63784 12.7136 9.36328C11.9882 10.0887 11.0239 10.4883 9.99936 10.4883Z"
                    fill="#40A9FF"
                  />
                </svg>
              }
              onClick={() => onShowModalForm(data?._id, data)}
            />
          </Space>
        )
      },
    },
  ]

  //Refresh data
  const refreshData = () => {
    const params = {
      search: keyword,
      pageSize: pageSize,
      page,
    }
    actions.getListUser(params)
  }
  // Handle open modal (create-edit-delete)
  const onShowModalForm = (id, user) => {
    setModalForm({
      id,
      isOpenModal: true,
      user: user,
    })
  }

  //   // Handle close modal (create-edit-delete)
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      user: {},
    })
  }

  const handleChangeRole = (e) => {
    setRole(USER_ROLE[e.key])
  }
  const handleSearch = (values) => {
    setKeyword(values?.keyword)
  }

  useEffect(() => {
    refreshData()
  }, [page, pageSize, keyword, role])

  const generateItemList = (itemList) =>
    itemList?.map((item) => ({
      key: item?._id,
      // label: item?.user_name,
      label: (
        <Row>
          <Col span={24}>Name: {item?.user_name}</Col>
          <Col span={24}>Wallet address: {item?.address_wallet}</Col>
        </Row>
      ),
      value: item?._id,
    }))

  const handleAddGift = (value) => {
    const selectedUser = listUser.find((item) => item._id === value)
    if (selectedUser) {
      const formData = {
        id: selectedUser._id,
        name: selectedUser.user_name,
      }
      setWhiteList((old) => [formData])
      formSearch.resetFields()
    }
  }
  return (
    <div>
      <Row style={{ marginBottom: 16 }} gutter={[16, 16]}>
        <Col xs={24} sm={12}>
          <div className="flex justify-center align-center">
            <Form
              onFinish={handleSearch}
              className="w-[80%] "
              form={formSearch}
              translate="yes"
              autoComplete="off"
              size="middle"
              validateTrigger={['onBlur', 'onChange']}
              requiredMark={false}
            >
              <Form.Item
                className="w-full"
                label={
                  <span className="font__M__plus font-bold">
                    {t('common.add')}
                  </span>
                }
                name="keyword"
                labelAlign="left"
              >
                <Select
                  className="w-full addUserList"
                  onChange={handleAddGift}
                  options={generateItemList(listUser)}
                  placeholder={t('common.add')}
                  showSearch
                  filterOption={(input, option) =>
                    option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <div className="flex sm:justify-end w-full">
            <div className="flex gap-0 sm:gap-3 flex-wrap mb-3 sm:mb-0 w-full sm:justify-end">
              <div className="flex items-baseline">
                <div className="mr-2 font__Rexliafree">
                  {t('managementUsers.sortBy')}
                </div>
                <Dropdown
                  className="flex items-center cursor-pointer ml-3 sort__value"
                  menu={{
                    items: USER_ROLE,
                    selectable: true,
                    onClick: handleChangeRole,
                  }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button className="flex !bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[160px]">
                    <div className="title">{role?.label}</div>
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Datatable
        scroll={{ x: 1300 }}
        columns={columns}
        dataSource={listUser}
        loading={isLoading}
        showPagination
        showHeader
        metadata={{
          perPage: pageSize,
          setPerPage: setPageSize,
          page,
          setPage,
          total: total,
          keyword,
          setKeyword,
        }}
      />

      {/* {modalForm.isOpenModal && (
        <Profile
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          refreshData={refreshData}
          id={modalForm.id}
          user={modalForm?.user}
        />
      )} */}
    </div>
  )
}

export default WhiteList

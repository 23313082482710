import PropTypes from 'prop-types'
import { useTranslation } from '~/common/hooks/useTranslation'
import { Button, Col, Input, Modal, Row } from 'antd'
import React, { useState } from 'react'
import RankingChart from './RankingChart'
import RankingPoolForm from '../form'
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  SettingOutlined,
} from '@ant-design/icons'

function RankingPoolDetail(props) {
  const { t } = useTranslation()
  const { id, isShowModal, onClose, refreshData, ranking } = props

  const [listCheckRanking, setListCheckRanking] = useState('Weekly')

  const handleClose = () => {
    onClose()
  }
  const [modalForm, setModalForm] = useState({
    id: null,
    isOpenModal: false,
    ranking: {},
  })
  const onShowModalForm = (id, ranking) => {
    setModalForm({
      id,
      isOpenModal: true,
      ranking: ranking,
    })
  }
  const onCloseModalForm = () => {
    setModalForm({
      id: null,
      isOpenModal: false,
      ranking: {},
    })
  }

  return (
    <Modal
      title={'Ranking Detail'}
      onCancel={handleClose}
      open={isShowModal}
      width={950}
      centered
      destroyOnClose
      footer={null}
    >
      <div>
        <Row>
          <Col span={8}>
            <div>
              <RankingChart dataRanking={ranking} />
            </div>
            <div className="mt-[15%]">
              <div className="flex gap-2 justify-center items-center">
                <Button>Deposit</Button>
                <Button>Withdraw</Button>
                <Button onClick={() => onShowModalForm(id, ranking)}>
                  <SettingOutlined />
                </Button>
              </div>
            </div>
            <div className="flex justify-center text-[24px] mt-3 mb-3">
              Reward Status
            </div>
            <div
              style={{
                border: '2px solid #fff',
                padding: '10px',
                marginBottom: '12px',
                borderRadius: '10px',
              }}
            >
              <div className="flex justify-center text-[20px]">
                Claimed in Sector
              </div>
              <div className="flex justify-center text-[16px] ">
                5340/10000 OAS
              </div>
              <div className="flex justify-center text-[16px] ">
                500/1000 Players
              </div>
            </div>
            <div
              style={{
                border: '2px solid #fff',
                padding: '10px',
                borderRadius: '10px',
              }}
            >
              <div className="flex justify-center text-[20px]">
                Claimed in Total
              </div>
              <div className="flex justify-center text-[16px]">
                20000/40000 OAS
              </div>
              <div className="flex justify-center text-[16px]">
                500/4000 Players
              </div>
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={15}>
            <div
              className="flex justify-center items-center mb-2"
              style={{ fontFamily: 'Rexliafree' }}
            >
              <img
                className="w-[25px] h-[25px] mr-2 mt-[2px]"
                src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                alt=""
              />
              <div className="text-[25px]">Ranking</div>
            </div>
            {listCheckRanking === 'Weekly' && (
              <div style={{ fontFamily: 'Rexliafree' }}>
                {/* member */}
                <div
                  className="guildInfo"
                  style={{
                    maxHeight: '515px',
                    overflowY: 'auto',
                    padding: '0 20px',
                  }}
                >
                  {/* 1 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgba(12, 200, 254, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[45px] h-[45px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/1.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 2 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(79, 252, 88, 1)',
                      padding: '10px',
                      background: 'rgba(79, 252, 88, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/2.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo2.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon2.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 3 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(238, 1, 9, 1)',
                      padding: '10px',
                      background: 'rgba(238, 1, 9, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/3.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo3.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 4 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(79, 252, 88, 1)',
                      padding: '10px',
                      background: 'rgba(79, 252, 88, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/4.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 5 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgba(12, 200, 254, 0.2)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/5.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 6 */}
                  <div
                    style={{
                      border: '2px solid rgba(238, 1, 9, 1)',
                      padding: '10px',
                      background: 'rgba(238, 1, 9, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/6.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Row className="mt-4">
                  <Col span={12} className="flex justify-center items-center">
                    <CaretLeftOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                    <div style={{ margin: '0 15%', fontSize: '18px' }}>
                      1 / 1,2324
                    </div>
                    <CaretRightOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                  </Col>
                  <Col span={12} className="flex justify-center items-center">
                    <img
                      className="w-[25px] h-[25px] mr-2 mt-[2px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                      alt=""
                    />
                    <Input style={{ width: '133px', height: '28px' }} />
                    <img
                      className="w-[30px] h-[30px] ml-2 cursor-pointer"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/searchSubmit.svg`}
                      alt=""
                    />
                  </Col>
                </Row>
              </div>
            )}
            {listCheckRanking === 'Cycle' && (
              <div style={{ fontFamily: 'Rexliafree' }}>
                {/* member */}
                <div
                  className="guildInfo"
                  style={{
                    maxHeight: '515px',
                    overflowY: 'auto',
                    padding: '0 20px',
                  }}
                >
                  {/* 1 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgba(12, 200, 254, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[45px] h-[45px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/1.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 2 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(79, 252, 88, 1)',
                      padding: '10px',
                      background: 'rgba(79, 252, 88, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/2.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo2.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>112,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>53,600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>495,000</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon2.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 3 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(238, 1, 9, 1)',
                      padding: '10px',
                      background: 'rgba(238, 1, 9, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/3.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo3.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>26,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>85,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>9,600</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 4 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(79, 252, 88, 1)',
                      padding: '10px',
                      background: 'rgba(79, 252, 88, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/4.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 5 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgba(12, 200, 254, 0.2)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/5.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 6 */}
                  <div
                    style={{
                      border: '2px solid rgba(238, 1, 9, 1)',
                      padding: '10px',
                      background: 'rgba(238, 1, 9, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/6.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />

                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/iconMember.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex flex-col ">
                            <div className="text-[20px]">Player Name</div>
                            <div className="text-[14px]">Beginner</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>600</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px] mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>400</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[35px] h-[35px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex">
                            <div
                              className="text-[18px]"
                              style={{ alignContent: 'center' }}
                            >
                              Guid Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8} className="mt-[6px]">
                        <Row>
                          <Col span={8}>
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>1 / 105</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Row className="mt-4">
                  <Col span={12} className="flex justify-center items-center">
                    <CaretLeftOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                    <div style={{ margin: '0 15%', fontSize: '18px' }}>
                      1 / 1,2324
                    </div>
                    <CaretRightOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                  </Col>
                  <Col span={12} className="flex justify-center items-center">
                    <img
                      className="w-[25px] h-[25px] mr-2 mt-[2px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                      alt=""
                    />
                    <Input style={{ width: '133px', height: '28px' }} />
                    <img
                      className="w-[30px] h-[30px] ml-2 cursor-pointer"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/searchSubmit.svg`}
                      alt=""
                    />
                  </Col>
                </Row>
              </div>
            )}
            {listCheckRanking === 'Guild' && (
              <div style={{ fontFamily: 'Rexliafree' }}>
                {/* member */}
                <div
                  className="guildInfo"
                  style={{
                    maxHeight: '515px',
                    overflowY: 'auto',
                    padding: '0 20px',
                  }}
                >
                  {/* 1 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[45px] h-[45px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/1.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 2 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/2.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 3 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/3.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon3.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 4 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/4.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon4.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 5 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/5.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon5.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  {/* 6 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgb(13, 39, 88)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-[-20px] top-[-3px] w-[40px] h-[40px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/level/6.svg`}
                      alt=""
                      style={{ zIndex: 1 }}
                    />
                    <Row>
                      <Col span={16}>
                        <Row>
                          <Col
                            span={5}
                            className="flex justify-center items-center"
                          >
                            <img
                              className="w-[50px] h-[50px] mr-2 mt-[10px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/testGuildIcon6.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center ">
                            <div className="text-[20px] mt-[10px]">
                              Guild Name
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[20px] h-[20px] mr-2 mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/score.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>121,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]  mt-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>61,000</Col>
                        </Row>
                        <Row>
                          <Col span={8} className="flex items-center">
                            <img
                              className="w-[15px] h-[15px] ml-[2px]"
                              src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={16}>405,000</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Row className="mt-4">
                  <Col span={12} className="flex justify-center items-center">
                    <CaretLeftOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                    <div style={{ margin: '0 15%', fontSize: '18px' }}>
                      1 / 1,2324
                    </div>
                    <CaretRightOutlined
                      style={{ fontSize: '25px', cursor: 'pointer' }}
                    />
                  </Col>
                  <Col span={12} className="flex justify-center items-center">
                    <img
                      className="w-[25px] h-[25px] mr-2 mt-[2px]"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                      alt=""
                    />
                    <Input style={{ width: '133px', height: '28px' }} />
                    <img
                      className="w-[30px] h-[30px] ml-2 cursor-pointer"
                      src={`${process.env.PUBLIC_URL}/svgs/guild/searchSubmit.svg`}
                      alt=""
                    />
                  </Col>
                </Row>
              </div>
            )}
            {listCheckRanking === 'Sector' && (
              <div style={{ fontFamily: 'Rexliafree' }}>
                {/* member */}
                <div
                  className="guildInfo"
                  style={{
                    maxHeight: '567px',
                    overflowY: 'auto',
                    padding: '0 20px',
                  }}
                >
                  {/* 1 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(12, 200, 254, 1)',
                      padding: '10px',
                      background: 'rgba(12, 200, 254, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo1.svg`}
                      alt=""
                    />
                    <Row>
                      <Col span={17}>
                        <Row>
                          <Col span={5} className="flex  items-center">
                            <img
                              className="w-[55px] h-[55px] mr-2 "
                              src={`${process.env.PUBLIC_URL}/svgs/guild/sector1.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center  ">
                            <div className="text-[27px]">Algo-Roots</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={7}
                        className=" flex items-center justify-center"
                        style={{
                          border: '1px solid rgba(0, 0, 0, 0.8)',
                          background: 'rgba(0, 0, 0, 0.8)',
                          borderRadius: '10px',
                          height: '55px',
                        }}
                      >
                        <img
                          className="w-[25x] h-[25px] mr-2"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rank.svg`}
                          alt=""
                        />
                        <div className="flex items-center ">
                          <div className="text-[45px] mb-[12px] ">1</div>
                          <div className="text-[25px]">th</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[25px]">
                        Sector Score
                      </Col>
                      <Col span={7} className="text-[25px] text-end">
                        145,786
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                          alt=""
                        />
                        <div className="ml-2">Ranked Match</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        80,987
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                          alt=""
                        />
                        <div className="ml-2">Exploration</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        64,987
                      </Col>
                    </Row>
                  </div>
                  {/* 2 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(79, 252, 88, 1)',
                      padding: '10px',
                      background: 'rgba(79, 252, 88, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo2.svg`}
                      alt=""
                    />
                    <Row>
                      <Col span={17}>
                        <Row>
                          <Col span={5} className="flex  items-center">
                            <img
                              className="w-[55px] h-[55px] mr-2 "
                              src={`${process.env.PUBLIC_URL}/svgs/guild/sector2.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center  ">
                            <div className="text-[27px]">Echo-Leaves</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={7}
                        className=" flex items-center justify-center"
                        style={{
                          border: '1px solid rgba(0, 0, 0, 0.8)',
                          background: 'rgba(0, 0, 0, 0.8)',
                          borderRadius: '10px',
                          height: '55px',
                        }}
                      >
                        <img
                          className="w-[25x] h-[25px] mr-2"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankSilver.svg`}
                          alt=""
                        />
                        <div className="flex items-center ">
                          <div className="text-[45px] mb-[12px] ">2</div>
                          <div className="text-[25px]">nd</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[25px]">
                        Sector Score
                      </Col>
                      <Col span={7} className="text-[25px] text-end">
                        135,786
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                          alt=""
                        />
                        <div className="ml-2">Ranked Match</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        75,987
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                          alt=""
                        />
                        <div className="ml-2">Exploration</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        59,987
                      </Col>
                    </Row>
                  </div>
                  {/* 3 */}
                  <div
                    className="mb-2"
                    style={{
                      border: '2px solid rgba(238, 1, 9, 1)',
                      padding: '10px',
                      background: 'rgba(238, 1, 9, 0.4)',
                      borderRadius: '20px',
                      position: 'relative',
                    }}
                  >
                    <img
                      className="absolute left-0 top-0 w-full h-full "
                      src={`${process.env.PUBLIC_URL}/svgs/guild/logo3.svg`}
                      alt=""
                    />
                    <Row>
                      <Col span={17}>
                        <Row>
                          <Col span={5} className="flex  items-center">
                            <img
                              className="w-[55px] h-[55px] mr-2 "
                              src={`${process.env.PUBLIC_URL}/svgs/guild/sector3.svg`}
                              alt=""
                            />
                          </Col>
                          <Col span={14} className="flex items-center  ">
                            <div className="text-[27px]">Bi-Flowers</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={7}
                        className=" flex items-center justify-center"
                        style={{
                          border: '1px solid rgba(0, 0, 0, 0.8)',
                          background: 'rgba(0, 0, 0, 0.8)',
                          borderRadius: '10px',
                          height: '55px',
                        }}
                      >
                        <img
                          className="w-[25x] h-[25px] mr-2"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankBronze.svg`}
                          alt=""
                        />
                        <div className="flex items-center ">
                          <div className="text-[45px] mb-[12px] ">3</div>
                          <div className="text-[25px]">rd</div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[25px]">
                        Sector Score
                      </Col>
                      <Col span={7} className="text-[25px] text-end">
                        115,786
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/rankedMatch.svg`}
                          alt=""
                        />
                        <div className="ml-2">Ranked Match</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        65,987
                      </Col>
                    </Row>
                    <Row>
                      <Col span={17} className="text-[20px] flex">
                        <img
                          className="w-[20px] h-[20px] mt-[2px]"
                          src={`${process.env.PUBLIC_URL}/svgs/guild/exploration.svg`}
                          alt=""
                        />
                        <div className="ml-2">Exploration</div>
                      </Col>
                      <Col span={7} className="text-[20px] text-end">
                        49,987
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            <div>
              <Row className="mt-8">
                <Col span={5}></Col>
                <Col
                  span={14}
                  className="flex gap-6 justify-center items-start "
                >
                  <Button onClick={() => setListCheckRanking('Weekly')}>
                    Weekly
                  </Button>
                  <Button onClick={() => setListCheckRanking('Cycle')}>
                    Cycle
                  </Button>
                  <Button onClick={() => setListCheckRanking('Guild')}>
                    Guild
                  </Button>
                  <Button onClick={() => setListCheckRanking('Sector')}>
                    Sector
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {modalForm.isOpenModal && (
        <RankingPoolForm
          isShowModal={modalForm.isOpenModal}
          onClose={onCloseModalForm}
          // refreshData={refreshData}
          id={modalForm.id}
          ranking={modalForm?.ranking}
        />
      )}
    </Modal>
  )
}

RankingPoolDetail.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  id: PropTypes.string,
}

export default RankingPoolDetail
